.verification_block {
    padding: 24px 32px;
    background: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    width: fit-content;
    margin-bottom: 36px;
}

.title {
    margin: 0 64px 0 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    word-break: keep-all;
}

.button_wrapper {
    width: 180px;
}

.questionnaire_list_block {
    padding: 24px 32px;
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    box-sizing: border-box;
}

.questionnaire_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.questionnaire_table {

}

.table_header {
    display: flex;
    gap: 12px;
    margin-bottom: 18px;
}

.header_value {
    width: 33%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin: 4px 0;
}

.table_body {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.table_row {
    display: flex;
    gap: 12px;
    border-bottom: 1.5px solid #F6F9FF;
}

.row_value {
    width: 33%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    margin: 4px 0;
}

.name {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
}

.rejected {
    color: #FF6471;
}

.confirmed {
    color: #74C69D;
}

.pending {
    color: #2A2E37;
}

.flex {
    display: flex;
    align-items: center;
}

.gap {
    gap: 24px;
}

.edit {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/images/personalAccount/edit.svg");
    background-color: #EEEFEF;
    margin-right: 6px;
    cursor: pointer;
}

.edit_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/images/personalAccount/edit.svg");
    background-color: #FEC979;
    margin-right: 6px;
    cursor: pointer;
}

.edit_text_active {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FEC979;
}

.edit_text_inactive {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #EEEFEF;
}

.delete {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/images/personalAccount/delete.svg");
    background-color: #EEEFEF;
    margin-right: 6px;
}

.action {
    cursor: pointer;
}

.delete_text_active {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FF8A94;
}

.delete_text_inactive {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #EEEFEF;
    transition: .5s all ease;
}

.delete_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../../../../../assets/images/personalAccount/delete.svg");
    background-color: #FF8A94;
    margin-right: 6px;
    transition: .5s all ease;
}

.action:hover .delete_text_active {
    color: #FF6471;
    transition: .5s all ease;
}

.action:hover .delete_active {
    color: #FF6471;
    transition: .5s all ease;
}

.questionnaire_table_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .questionnaire_list_block {
        background: transparent;
        width: 100%;
        padding: 0;
    }

    .questionnaire_table {
        display: none;
    }

    .questionnaire_table_mobile {
        display: block;
        border-radius: 8px;
        background: #FFF;
        padding: 16px;
    }

    .row_mobile {
        display: flex;
        justify-content: space-between;
        gap: 23px;
        padding-bottom: 16px;
        border-bottom: 1px solid #EEEFEF;
    }

    .block {
        width: 100%;
    }

    .label {
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        margin-bottom: 9px;
    }

    .row_value {
        width: 100%;
        margin: 4px 0;
    }

    .action {
        width: 100%;
        cursor: pointer;
    }

    .name {
        width: auto;
    }

    .verification_block {
        padding: 32px 16px;
        flex-direction: column;
        gap: 24px;
        width: 100%;
    }

    .button_wrapper {
        width: 100%;
    }

    .title {
        margin: 0 0 0 16px;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #2A2E37;
        word-break: keep-all;
    }
}

