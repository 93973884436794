.inputWrapper {
  position: relative;
}

.view {
  display: block;
}

.passwordView {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/images/common/no-view.svg) 0 0 no-repeat;
}

.passwordControl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/images/common/view.svg) 0 0 no-repeat;
}

.my_input {
  width: 100%;
  height: 52px;
  background: #FFFFFF;
  border: 1.5px solid #EEEFEF;
  border-radius: 8px;
  padding: 18px;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #2A2E37;
}

.my_input:focus {
  outline: 1.5px solid #979797;
}

.my_input:hover {
  outline: 1.5px solid #979797;
}

.hidden {
  display: none;
}

.error_input {
  border: 1.5px solid #FF6471!important;
}


