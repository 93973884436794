.sign_in_wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.sign_in_content {
    width: 315px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2A2E37;
    margin-bottom: 20px;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
}

.my_input {
    width: 315px;
    height: 52px;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    border-radius: 8px;
    padding: 18px;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.my_input:focus {
    outline: 1.5px solid #979797;
}

.my_input:hover {
    outline: 1.5px solid #979797;
}

.input_block {
    margin-bottom: 0;
}

.forgot {
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
    cursor: pointer;
}

.error {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
}



.my_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 156px;
    height: 48px;
    box-sizing: border-box;
    background: #E2B778;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: .3s all ease;
}

.my_btn:hover {
    background: #D6AD72;
    transition: .3s all ease;
}

.dont_have_account {
    margin-top: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
}

.link {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
    margin-left: 8px;
    text-decoration: none;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #FF6471;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.subtitle {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #979797;
    margin-bottom: 20px;
}

.bold {
    font-size: 16px;
    line-height: 20px;
    margin: 20px 0 40px;
}

.timer_wrapper {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #2A2E37;
    margin-top: 20px;
}

.modal_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2A2E37;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
}

.flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_block {
    margin-top: 60px;
    width: 180px;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #FF6471;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.error_wrapper {
    width: 315px;
}

@media only screen
and (max-device-width: 768px) {


}
