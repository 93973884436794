.accountOverviewWrapper {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    gap: 24px;
}

.accountsTitle {
    color: #979797;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

.accountTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 26px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 16px !important;
    text-transform: uppercase;
    border: none !important;
}

.accountTable tbody {
    height: 200px;
}

.accountTable tbody > tr > td {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0px !important;
    height: fit-content !important;
}

.buttonWrapper {
    padding-top: 24px;
    float: right;
    width: 142px;
}

.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.pending {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFBB54;
    margin: 0;
    text-transform: lowercase;
}

.blocked {  font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    color: #FF6471;
    text-transform: lowercase;
}

.disabled {
    color: #979797;
    margin: 0;
}