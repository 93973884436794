.button {
    padding: 16px 32px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
    height: fit-content;
    border-radius: 8px;
    transition: 300ms !important;
    z-index: 2;
    width: 100%;
    border: 1.5px solid var(--primary-color);
}

.button span {
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    color: var(--primary-color) !important;
    transition: .3s all ease;
}

.button:hover span {
    color: var(--secondary-color)!important;
    transition: .3s all ease;
}

svg path {
    transition: 300ms !important;
}

.button:hover svg path {
    fill: var(--secondary-color);
    transition: 300ms !important;
}

.disabled,
.disabled:hover {
    background-color: #979797;
    transition: background-color .3s ease;
    cursor: progress;
}

.confirm {
    border-color: #FFBB54 !important;
}

.confirm span {
    color: #FFBB54 !important;
}

.confirm:hover {
    border-color: #FEC979 !important;
    color: #FEC979 !important;
}

.confirm:hover span{
    color: #FEC979 !important;
}

.confirm svg path {
    fill: #FFBB54;
}

.confirm:hover svg path {
    fill: #FEC979;
}

.error {
    border-color: #FF6471 !important;
    color: #FF6471 !important;
}

.error:hover {
    border-color: #FF8A94 !important;
    color: #FF8A94 !important;
}

.error:hover span{
    border-color: #FF8A94 !important;
    color: #FF8A94 !important;
}

.error svg path {
    fill: #FF6471;
}


.error:hover svg path {
    fill: #FF8A94;
}

.error span {
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 20px;
    text-transform: capitalize;
    color: #FF6471!important;
    transition: .3s all ease;
}

.medium {
    height: 44px;
}

.large {
    height: 52px;
}

.small {
    height: fit-content;
    padding: 12px 24px;
}


@media only screen
and (max-device-width: 768px) {
    .my_btn {
        width: 100%;
    }
}
