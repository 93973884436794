
.input_block {
    width: 100%;
    position: relative;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
    width: max-content;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.input_label img {
    cursor: pointer;
}

.row {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin-bottom: 24px;
}

.input_block div.flex_label {
    gap: 24px;
}

.block {
    margin-top: 48px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.languages_dropdown {
    width: 92px;
}

.short {
    width: 208px;
}

.input_block_fixed {
    width: 316px;
    position: relative;
}

.input_block div.flex_label {
    gap: 24px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.type_of_payment_active {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.type_of_payment_inactive {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
}

.continue_block {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
}

.button_wrapper {
    width: 180px;
    position: relative;
}

.required {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #2A2E37;
}

.mobile_row {
    display: flex;
    gap: 16px;
}

.add_dock_block {
    margin-top: 48px;
}

.attached_file_wrapper {
    display: flex;
    align-items: center;
    gap: 32px;
}

.invoice_doc_name {
    color: var(--primary-color);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.attached_file {
    display: flex;
    align-items: center;
    gap: 8px;
}

.attached_file img {
    cursor: pointer;
}

.invoice_img {
    border-radius: 12px;
    background: #EEEFEF;
    padding: 16px;
}

.invoice_title {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
}

.invoice_desc {
    color: #101010;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.flex_start {
    align-items: flex-start;
}

.hidden_input {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

@media only screen
and (max-device-width: 768px) {
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;
    }

    .input_block_fixed {
        width: 100%;
    }

    .mobile_row {
        display: flex;
        gap: 8px;
        width: 100%;
    }

    .short {
        width: 100%;
    }
}
