.pageWrapper {
    padding: 24px 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    min-height: 615px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
}

.successIcon {
    width: 100px;
    height: 100px;
    display: block;
    padding: 0;
    background-color: var(--primary-color);
    mask-image: url("/src/assets/images/personalAccount/succcess.svg");
    mask-repeat: no-repeat;
    position: relative;
}

.title {
    text-align: center;
    color: #2A2E37;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
    max-width: 500px;
}

.subtitle {
    text-align: center;
    color: #979797;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    word-wrap: break-word;
    max-width: 550px;
}

.buttonWrapper {
    width: 190px;
}