.textarea {
    padding: 17px 16px;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    border-radius: 8px;
    min-height: 104px;
    resize: none;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    box-sizing: border-box;
}

.textarea:focus {
    outline: none !important;
    border: 1.5px solid #979797 !important;
}

.error {
    border: 1.5px solid #FF6471;
}
