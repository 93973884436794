.input_name {
    margin-bottom: 7px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: 0.04em;
    color: #5A6061;
}

.my_input {
    padding: 9px 0;
    min-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 52px;
    background: #FFFFFF;
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    border: 1.5px solid #EEEFEF;
    position: relative;
}
.error_border {
    border: 1.5px solid #FF6471;
}

.my_input:focus {
    outline: 1.5px solid #979797;
}

.my_input div {
    border: none!important;
    outline: none!important;
    box-shadow: none!important;
    padding: 0 18px!important;
}

.my_input div span {
    font-family: 'Inter', sans-serif!important;
    font-style: normal!important;
    font-weight: 600!important;
    font-size: 14px!important;
    color: #2A2E37!important;
}

.my_input div span:first-child {
    inset-inline-start: 18px!important;
    inset-inline-end: 18px!important;
}

.select_wrapper {
position: relative;
}

.my_dropdown {
font-family: 'Inter', sans-serif!important;
font-style: normal!important;
font-weight: 600!important;
font-size: 14px!important;
line-height: 18px!important;
color: #2A2E37!important;
}
