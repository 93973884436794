.wrapper {
    background: #FFFFFF;
    border-radius: 12px;
    width: 860px;
    padding: 24px 32px;
    box-sizing: border-box;
}

.accounts_list_block {
    margin-bottom: 48px;
}

.list_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.accounts_list {
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1.5px solid #F6F9FF;
}

.accounts_list_header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.list_block {
    width: 33%;
}

.list_block_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
}

.list_block_value {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    height: 64px;
    display: flex;
    align-items: center;
    max-width: 100%;
    word-break: break-all;
}

.change_password_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.change_password_subtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
    margin-bottom: 48px;
}

.input_block {
    width: 316px;
    margin-bottom: 24px;
    position: relative;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.button_wrapper {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
}

.email_block {
    margin-bottom: 48px;
    margin-top: 48px;
}

.need_to_verify {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
    cursor: pointer;
    transition: .3s all ease
}

.need_to_verify:hover {
    color: var(--secondary-color);
    transition: .3s all ease
}

.need_to_verify path {
    fill: var(--primary-color);
    transition: .5s all ease
}

.need_to_verify:hover path {
    fill: var(--secondary-color);
    transition: .5s all ease
}

.set_as_main {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FEC979;
    cursor: pointer;
    transition: .5s all ease;
}

.tick_svg {
    width: 24px;
    height: 24px;
    background-color: #FEC979;
    transition: .5s all ease;
    mask-image: url("../../../src/assets/images/personalAccount/tick.svg");
}

.set_as_main:hover {
    color: #FFBB54;
    transition: .5s all ease;
}

.set_as_main:hover .tick_svg {
    background-color: #FFBB54;
    transition: .5s all ease;
}

.delete_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../../src/assets/images/personalAccount/delete.svg");
    background-color: #FF8A94;
    margin-right: 6px;
    transition: .5s all ease;
}

.action {
    cursor: pointer;
}

.action path {
    fill: #FF6471;
    transition: .5s all ease;
}

.action:hover path {
    fill: #FF8A94;
    transition: .5s all ease;
}

.delete_text_active {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FF6471;
    transition: .5s all ease;
    margin-left: 4px;
}

.flex {
    display: flex;
    align-items: center;
}

.action:hover .delete_text_active {
    color: #FF8A94;
    transition: .5s all ease;
}

.field_error {
    position: absolute;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    bottom: -18px;
    color: #FF6471;
}


.modal_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #2A2E37;
}

.modal_subtitle {
    margin-top: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #979797;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.button_block {
    margin-top: 60px;
    width: 180px;
}

.btn_block {
    margin-top: 40px;
}

.mobile_email_block {
    display: none;
}

.disabled {
    color: #979797;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    gap: 6px;
}

.disabled path {
    fill: #979797
}

.disabled .tick_svg{
    background-color: #979797;
}


.add_phone_popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.input_block {
    width: 316px;
    margin-bottom: 24px;
    position: relative;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.from_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
}

.error {
    color: #FF6471;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
}

.verify_subtitle {
    color: #979797;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
}

.email_for_verify {
    color: #979797;
    font-weight: 600;
}

@media only screen
and (max-device-width: 768px) {

    .desktop_email_block {
        display: none;
    }

    .mobile_email_block {
        display: block;
    }

    .wrapper {
        width: 100%;
        padding: 0;
        background: transparent;
    }

    .accounts_list_block {
        padding: 32px 16px 48px 16px;
        background-color: #fff;
        margin-bottom: 0;
        border-radius: 12px 12px 0 0;
    }

    .form {
        background-color: #fff;
        border-radius: 0 0 12px 12px;
        padding: 32px 16px 32px 16px;
    }

    .mobile_email_block {
        border-radius: 8px;
        background: #FFF;
        padding: 16px;
    }

    .list_block_value {
        height: auto;
        margin-top: 19px;
    }

    .mobile_email {
        width: 100%;
    }

    .accounts_list {
        justify-content: space-between;
        border-bottom: none;
    }

    .button_block {
        margin-top: 32px;
        width: 100%;
    }

    .input_block {
        width: 100%;
    }

    .list_block {
        width: 100%;
    }
}
