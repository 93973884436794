.wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #EEEFEF;
    background: #FFF;
    padding: 46px 16px;
    box-sizing: border-box;
    justify-content: space-between;
}

.right {
    display: flex;
    align-items: center;
    gap: 32px;
}

.login_wrapper {
    position: relative;
}

.login_header{
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
}

@media only screen
and (max-device-width: 768px) {

    .logo_login {
        position: absolute;
        width: 34px;
        height: 40px;
        top: 32px;
        left: 16px;
        z-index: 1;
    }

    .company_name {
        font-family: 'Krona One', sans-serif;
        position: absolute;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        color: white;
        font-size: 20px;
        font-weight: 500;
        text-transform: uppercase;
    }

}
