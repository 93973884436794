.statementWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    cursor: default;
    margin: 51px 0 31px;
    max-width: 720px;
}


.downloadTitle {
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 8px;
}

.buttonTitle {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-color);
    cursor: pointer;
    margin: 0;
}


.separator {
    width: 1px;
    height: 14px;
    background-color: #2A2E37;
}

.printWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
}

.printWrapper:hover > *{
    color: var(--primary-color);
}

.printWrapper:hover > svg {
    fill: var(--primary-color);
}

.print {
    font-weight: 700;
    color: var(--primary-color);
    margin: 0;
}


@media only screen
and (min-device-width: 320px)
and (max-device-width: 624px) {
    .statementWrapper {
        font-size: 16px;
        position: initial;
        margin-top: 24px;
        flex-direction: column;
        gap: 40px;
        align-items: flex-start;
    }

    .buttonsWrapper {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
    }
}
