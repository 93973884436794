.createCardWrapper {
    padding: 24px 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    min-height: 615px;
    gap: 12px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    flex-direction: column;
}

.countryText {
    margin-bottom: 24px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.cardIcon {
    width: 24px;
    height: 24px;
    display: block;
    padding: 0;
    background-color: #979797;
    mask-image: url("/src/assets/images/personalAccount/cards.svg");
    mask-repeat: no-repeat;
    position: relative;
}

.cardsText {
    color: #979797;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0;
    font-weight: 500;
    word-wrap: break-word;
}

.buttonWrapper {
    width: 180px;
}

.hr {
    width: 100%;
    height: 100%;
    border: 1px #DEEFFC solid;
}
