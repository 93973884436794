.form {
    padding: 25px 32px;
    background-color: #FFFFFF;
    border-radius: 12px;
    max-width: 720px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.labelText {
    margin: 0 0 9px;
}

.periodRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.dropdown_wrapper {
    width: 316px;
}

.period, .account  {
    text-transform: initial;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.purposeRow {
    display: flex;
    flex-direction: column;
}


.inputWrapper {
    width: 100%;
    position: relative;
}

.recipientAmount {
    display: flex;
    justify-content: space-between;
    gap: 24px;
}

.transactionsBlock {
    display: flex;
    flex-direction: column;
    gap: 11px;
}

.checkboxWrapper {
    display: flex;
    gap: 32px;
    align-items: center;
}

.checkbox {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    color: #2A2E37;
}

.buttonWrapper{
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 8px;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}

@media only screen
and (max-device-width: 768px) {
    .form {
        padding: 32px 16px;
    }

    .dropdown_wrapper {
        width: 100%;
    }

    .periodRow {
        flex-wrap: wrap;
        gap: 16px;
    }
}

