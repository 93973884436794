.phone_numbers_block {
    margin-top: 48px;
}

.button_block {
    width: 180px;
}

.button_wrapper {
    display: flex;
    margin-top: 32px;
    justify-content: flex-end;
}

.need_to_verify {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
    cursor: pointer;
    transition: .3s all ease
}

.need_to_verify:hover {
    color: var(--secondary-color);
    transition: .3s all ease
}

.need_to_verify path {
    fill: var(--primary-color);
    transition: .5s all ease
}

.need_to_verify:hover path {
    fill: var(--secondary-color);
    transition: .5s all ease
}

.set_as_main {
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FEC979;
    cursor: pointer;
    transition: .5s all ease;
}

.tick_svg {
    width: 24px;
    height: 24px;
    background-color: #FEC979;
    transition: .5s all ease;
    mask-image: url("../../assets/images/personalAccount/tick.svg");
}

.set_as_main:hover {
    color: #FFBB54;
    transition: .5s all ease;
}

.set_as_main:hover .tick_svg {
    background-color: #FFBB54;
    transition: .5s all ease;
}

.disabled {
    color: #979797;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    gap: 6px;
}

.disabled path {
    fill: #979797
}

.disabled .tick_svg{
    background-color: #979797;
}

.delete_active {
    width: 24px;
    height: 24px;
    mask-image: url("../../assets/images/personalAccount/delete.svg");
    background-color: #FF8A94;
    margin-right: 6px;
    transition: .5s all ease;
}

.action {
    cursor: pointer;
}

.action path {
    fill: #FF6471;
    transition: .5s all ease;
}

.action:hover path {
    fill: #FF8A94;
    transition: .5s all ease;
}

.delete_text_active {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #FF6471;
    transition: .5s all ease;
    margin-left: 6px;
}

.flex {
    display: flex;
    align-items: center;
}

.action:hover .delete_text_active {
    color: #FF8A94;
    transition: .5s all ease;
}


.list_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.phones_list {
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1.5px solid #F6F9FF;
}

.phones_list_header {
    display: flex;
    align-items: center;
    gap: 12px;
}

.list_block {
    width: 33%;
}

.list_block_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
}

.list_block_value {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    height: 64px;
    display: flex;
    align-items: center;
}

.add_phone_popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.input_block {
    width: 316px;
    margin-bottom: 24px;
    position: relative;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.from_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.buttonsWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
}

.error {
    color: #FF6471;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-top: 4px;
}

.verify_subtitle {
    color: #979797;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
    width: 316px;
}

.verify_phone {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
}

.phones_list_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .phones_list_header {
        display: none;
    }

    .phones_list {
        display: none;
    }

    .phones_list_mobile {
        display: block;
    }

    .phone_card {
        padding: 16px;
        border-radius: 8px;
        background: #FFF;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 41px;
    }

    .block {
        width: 100%;
    }

    .label {
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }

    .list_block_value {
        font-size: 14px;
        line-height: 18px;
        height: auto;
        margin-top: 8px;
    }

    .button_block {
        width: 100%;
    }

    .buttonsWrapper {
        width: 100%;
    }

}
