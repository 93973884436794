.wrapper {
    border-radius: 12px;
    background: #FFF;
    width: 720px;
    box-sizing: border-box;
    padding: 24px 32px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.flex {
    display: flex;
    align-items: flex-end;
    gap: 24px;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.dropdown_block {
    width: 316px;
}

.amount_input {
    width: 208px;
    margin-right: 16px;
    position: relative;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}

.amount_block {
    display: flex;
    align-items: flex-end;
}

.currency_input_block {
    width: 92px;
}

.button_wrapper {
    display: flex;
    justify-content: flex-end;
}

.button_block {
    width: 180px;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        border-radius: 12px;
        background: #FFF;
        width: 100%;
        box-sizing: border-box;
        padding: 32px 16px;
    }

    .flex {
        flex-direction: column;
    }

    .dropdown_block {
        width: 100%;
    }

    .amount_input {
        width: 100%;
        margin-right: 16px;
    }

    .amount_block {
        width: 100%;
    }
}
