.formTemplatesWrapper {
    padding: 24px 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    max-width: 864px;
    margin-bottom: 37px;
}

.transfersWrapper {
    padding: 24px 32px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    margin-bottom: 37px;
}


.transfersWrapperTitle {
    padding: 0;
    margin-bottom: 32px;
}

.form {
    display: flex;
    flex-direction: column;
}

.accountInfoRow {
    display: flex;
    gap: 24px;
    height: 76px;
    margin-bottom: 32px;
}

.dropdown_wrapper {
    width: 316px;
    height: 52px;
}

.inputsWrapper {
    display: flex;
    gap: 24px;
}

.inputWrapper {
    width: 316px;
}

.buttonWrapper {
    height: 100%;
    align-self: flex-end;
}

.labelText {
    color: #979797;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
    padding: 0;
}

.tableButtonWrapper {
    margin-top: 32px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}


.transfersTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: uppercase;
    border: none !important;
}

.transfersTable thead > tr > th::before {
    content: none !important;
}

.transfersTable tbody > tr > td {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
}

.linkDiv {
    color: var(--primary-color) !important;
    text-transform: initial !important;
    text-decoration: underline;
    width: fit-content;
    cursor: pointer;
    transition: all .3s ease-in-out;
    padding: 0 15px;
}

.linkDiv:hover {
    color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}


tbody .typeColumn {
    text-transform: initial !important;
}

.actionBlock {
    display: flex;
    gap: 4px;
    color: #FF6471;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-transform: initial;
    cursor: pointer;
    transition: all .3s ease-in-out;
    align-items: center;
}

.actionBlock:hover {
    color: #FF8A94;
}

.deleteIcon {
    height: 16px;
    width: 16px;
    transition: all .3s ease-in-out;
}

.actionBlock:hover svg path {
    fill: #FF8A94;
    transition: all .3s ease-in-out;
}

.templateMobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .accountInfoRow {
        display: flex;
        flex-direction: column;
        gap: 24px;
        height: auto;
        margin-bottom: 32px;
    }

    .dropdown_wrapper {
        width: 100%;
        height: auto;
    }

    .inputsWrapper {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .inputWrapper {
        width: 100%;
    }

    .buttonWrapper {
        width: 100%;
    }

    .transfersTable {
        display: none;
    }

    .templateMobile {
        border-radius: 12px;
        background: #FFF;
        padding: 32px 16px;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .transfersWrapper {
        padding: 0;
        background-color: transparent;
    }

    .templateLabel {
        margin-bottom: 9px;
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }

    .templateValue {
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        word-break: break-word;
    }

    .flex {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    .flex div {
        width: 50%;
    }

    .linkDiv {
        display: block;
        text-align: left;
        width: 100%;
        padding: 0;
        word-break: break-word;
    }


}
