.wrapper {
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    padding: 24px 32px;
    box-sizing: border-box;
    margin-bottom: 135px;
    font-family: 'Inter', sans-serif;
    word-wrap: break-word;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        width: auto;
        padding: 32px 16px;
    }
}

.title {
    color: #2A2E37;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 32px;
}

.formTitle {
    color: #2A2E37;
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
    margin-bottom: 12px;
}

.warningText {
    color: #FF6471;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}

.row {
    display: inline-flex;
    padding: 0 12px 32px;
    gap: 32px;
    align-items: flex-start;
}

.inputBlock {
    width: 288px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
}

.inputBlock span {
    color: #979797;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    display: flex;
}

.infoRow {
    padding-bottom: 32px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}


.formSpan {
    color: #979797;
    display: inline-flex;
}

.link {
    color: var(--primary-color);
    margin-left: 3px;
    border-bottom: 1px var(--primary-color) solid;
}

.buttonsWrapper {
    display: flex;
    justify-content: center;
    gap: 98px;
}

.error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    position: absolute;
}


.popup_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    word-break: break-word;
    color: #2A2E37;
}

.popup_subtitle {
    display: block;
    text-align: center;
    width: 100%;
    font-weight: 400;
    line-height: 20px;
    color: #979797;
    margin-top: 20px;
    margin-bottom: 60px;
}

.btn_block {
    width: 180px;
}

.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
