.transactionsWrapper {
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
    gap: 24px;
}

.transactionsTitle {
    color: #979797;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;
}

.transactionsTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 26px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 16px !important;
    text-transform: uppercase;
    border: none !important;
}

.transactionsTable thead > tr > th > div > span {
    flex: initial !important;
}

.transactionsTable .ant-table-column-sorter {
    display: none !important;
}

.transactionsTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}

.transactionsTable thead > tr > th::before {
    content: none !important;
}