.app {
    position: relative;
}

html,
body {
    background: #F9FCFF;
    max-width: 100vw;
    overflow-x: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

h1, h2, h3, h4 ,p {
    margin: 0;
    padding: 0;
}

body header {
    padding: 0 48px;
    height: 98px;
    display: flex;
    align-items: center;
}

span {
    color: #77797E;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
}

h1 {
    color: #2A2E37;
    font-family: Mulish, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1.28px;
}

.desktop_header {
    display: block;
}

.mobile_header {
    display: none;
}

.modal_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.modal_subtitle {
    color: #979797;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    display: inline-block;
    margin-bottom: 60px;
}

.button_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_block {
    width: 180px;
}

@media only screen
and (max-device-width: 768px) {
    .desktop_header {
        display: none;
    }

    .mobile_header {
        display: block;
    }

    h1 {
        color: #2A2E37;
        text-align: center;
        font-family: Mulish, sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span {
        color: #77797E;
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}
