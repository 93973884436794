.wrapper {
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    padding: 24px 32px;
    box-sizing: border-box;
    margin-bottom: 32px;
}

.description_wrapper {
    width: 720px;

    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.description_wrapper p {
    margin-bottom: 12px;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        width: auto;
        padding: 32px 16px;
    }

    .description_wrapper {
        width: auto;
    }
}
