.iti {
    min-width: 288px;
    width: 100%;
    height: 100%;
    min-height: 55px;
}

.iti__tel-input {
    min-width: 288px;
    width: 100%;
    height: 100%;
    min-height: 55px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1.5px solid #EEEFEF;
    outline: none;
    box-sizing: border-box;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.iti__tel-input-error {
    border: 1.5px solid #FF6471;
}

.intl-tel-input {
    width: 100%;
}

.intl-tel-input .country-list {
    background: #fff;
    z-index: 5!important;
    overflow: visible;
}
