.message_block {
    margin-bottom: 60px;
    width: 100%;
}

.form_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 52px 52px 0 52px;
}

.modal_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    word-break: break-word;
    color: #2A2E37;
    margin-bottom: 60px;
}



.subject_block {
    margin-bottom: 24px;
    text-align: start;
    align-self: center;
    width: 100%;
}

.subject_block span {
    color: #979797;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.buttons_wrapper {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    gap: 24px;
}

.text_block {
    height: 168px;
}

.popup_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    word-break: break-word;
    color: #2A2E37;
}

.popup_subtitle {
    display: block;
    text-align: center;
    width: 100%;
    font-weight: 400;
    line-height: 20px;
    color: #979797;
    margin-top: 20px;
    margin-bottom: 60px;
}

.btn_block {
    width: 180px;
}

.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error_message {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    position: relative;
}
.currency_box {
    width: 30%;
    padding-left: 5px;
}

.amount_box {
    width: 70%;
}


.input_block {
    width: 100%;
    display: inline-flex;
}