.wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 999999;
}

.content_wrapper {
    width: 600px;
    height: fit-content;
    background: #FFFFFF;
    border-radius: 12px;
    position: absolute;
}

.content_wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 32px;
    background-color: var(--primary-color);
}

.short_content {
    padding: 52px;
}

.content {
    padding: 112px 40px;
    box-sizing: border-box;
}

.confirmModal::before {
    background-color: #FFBB54;
}

.error::before {
    background-color: #FF6471;
}

.contact::before {
    background-color: transparent;
}

@media only screen
and (max-device-width: 768px) {
    .content_wrapper {
        width: 100%;
    }

    .content {
        padding: 80px 40px;
        box-sizing: border-box;
    }
}
