.container {
    width: 1440px;
    padding: 0 100px;
    box-sizing: border-box;
    margin: 0 auto;
}

.wrapper {
    margin: 70px 0 122px;
}

.tabstitle_block {
    width: fit-content;
    border-radius: 8px;
    padding: 6px;
    border: 1.5px solid #EEEFEF;
    display: flex;
    list-style-type: none;
    margin-bottom: 36px;
}

.tabtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #2A2E37;
}

.active_tab {
    background: #FFFFFF;
    border-radius: 4px;
    color: var(--primary-color);
    outline: none;
}

@media only screen
and (max-device-width: 1440px) {
    .container {
        width: 100%;
    }
}

@media only screen
and (max-device-width: 768px) {
    .container {
        width: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .tabstitle_block {
        width: fit-content;
        border-radius: 8px;
        padding: 6px;
        border: 1.5px solid #EEEFEF;
        display: flex;
        list-style-type: none;
        margin-bottom: 36px;
    }

    .tabtitle {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #979797;
        text-decoration: none;
        padding: 8px 24px;
        cursor: pointer;
        transition: .3s all ease;
        text-align: center;
    }

    .tabtitle:hover {
        transition: .3s all ease;
        color: #2A2E37;
    }

    .active_tab {
        background: #FFFFFF;
        border-radius: 4px;
        color: var(--primary-color);
        outline: none;
    }
}
