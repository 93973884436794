.wrapper {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.btn_block {
    display: flex;
    justify-content: center;
    width: 100%;
}

.btn_wrapper {
    width: 315px;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #FF6471;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.error_wrapper {
    width: 315px;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        height: auto;
        margin-top: 55px;
    }
}
