.wrapper_mobile {
    display: none;
}

.wrapper_desktop {
    display: block;
}

.header_wrapper {
    background: #F6F9FF;
}

.static_column {
    width: 74px;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(236, 236, 236, 0.07);
    z-index: 2;
}

.logo {
    margin-bottom: 64px;
    float: left;
}

.links_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 20px;
    text-align: left;
}

.services_block {
    margin-top: 56px;

}

.nav_item {
    display: flex;
    width: 100%;
    height: 24px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 32px;
}

.layout {
    z-index: 3;
}

.img_block {
    width: 24px;
    height: 100%;
}

.yellow {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    margin-left: 16px;
}

.left {
    color: #000;
    display: flex;
    align-items: center;
}

.icons_block {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.round {
    width: 60px;
    height: 60px;
    background: #FFFFFF;
    border: 1.5px solid #EEEFEF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.user_round {
    width: 60px;
    height: 60px;
    background: #EEEFEF;
    border: 1.5px solid #EEEFEF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #979797;
    text-transform: uppercase;
}

.icon_point {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 10px;
    height: 10px;
}

.chat {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #2A2E37;
    display: block;
    mask-image: url("../../assets/images/personalAccount/header_chat_icon.svg");
}

.round:hover .chat {
    transition: .3s all ease;
    background-color: #979797;
}

.mail {
    transition: .3s all ease;
    width: 24px;
    height: 24px;
    background-color: #2A2E37;
    display: block;
    mask-image: url("../../assets/images/personalAccount/header_message_icon.svg");
}

.round:hover .mail {
    transition: .3s all ease;
    background-color: #979797;
}

.right {
    color: #000;
    height: 32px;
    display: flex;
    align-items: center;
}

.dropdown_language {
    display: flex;
    align-items: center;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: right;
    color: #131313;
    cursor: pointer;
    margin-right: 20px;
}

.welcome_block {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
}

.caps {
    text-transform: uppercase;
}

.chat_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
}

.btn_header {
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #131313;
    padding-left: 20px;
    margin-left: 20px;
    border-left: 1px solid #BCBEC3;
    cursor: pointer;
}

.btn_block {
    display: flex;
    align-items: center;
    padding: 6px 0;
}


.modal_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 60px;
}

.subject_block {
    margin-bottom: 24px;
    position: relative;
}

.subject_block span {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.message_block {
    margin-bottom: 60px;
    width: 100%;
}

.message_block span {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.modal_subtitle {

}

.button_block {
    width: 180px;
}

.error {
    color: #FF6471;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    position: absolute;
    border-bottom: 20px;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.wrapper_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper_desktop {
        display: none;
    }

    .wrapper_mobile {
        display: block;
    }

    .sider {
        display: none;
    }

    .header_wrapper {
        display: none;
    }
}

@media only screen
and (max-device-height: 788px) {

    .modal_title {
        margin-bottom: 25px;
    }

    .subject_block {
        margin-bottom: 12px;
    }
}
