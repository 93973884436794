.myPopup {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #FFF;
    border-radius: 0 0 4px 4px;
    top: 0;
    right: 0;
    z-index: 12;
    color: #fff;
    padding: 16px 16px 70px 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.close_img_block {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom: 1.5px solid #EFEFEF;
}

.closeImg {
    cursor: pointer;
    height: 40px;
}

.active {
    margin-right: 0;
    transition: margin-right 500ms ease;
}

.inactive {
    margin-right: -100%;
    transition: margin-right 500ms ease;
}

.links_block {
    margin-top: 26px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1.5px solid #EFEFEF;
}

.link {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.36px;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 30px;
}

.services_submenu_active {
    height: 276px;
    transition: height 500ms ease;
    width: 100% ;

    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 70px;
}

.services_submenu_inactive {
    height: 0;
    transition: height 500ms ease;
}

.arrow_reverse {
    transform: rotate(180deg);
    transition: transform 500ms ease;
}

.arrow_initial {
    transform: rotate(0deg);
    transition: transform 500ms ease;
}

.dropdown_link {
    width: 195px;
    display: flex;
    align-items: center;
    gap: 14px;
}

.link_text {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    transition: .5s all ease;
}

.dropdown_link:hover path {
    fill: #2A2E37;
}

.dropdown_link:hover .link_text{
    color: #2A2E37!important;
    transition: .5s all ease;
}

.buttons_block {
    margin-top: 32px;
    width: 166px;
}

@media only screen
and (max-device-width: 768px) {
    .myPopup {
        padding: 0 16px 70px 26px;
    }
}
