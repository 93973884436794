.wrapper {
    background: #FFFFFF;
    border-radius: 12px;
    width: 650px;
    min-height: 393px;
    padding: 24px 32px;
    box-sizing: border-box;
    margin-bottom: 135px;
    font-family: 'Inter', sans-serif;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px;
}

.block {
    display: flex;
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
}

.title {
    color: #2A2E37;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
}

.row {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: space-between;
    align-self: stretch;
}

.rowTitle {
    color: #2A2E37;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    word-wrap: break-word
}

.rowSpan {
    color: #979797;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    word-wrap: break-word
}

.buttonWrapper {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}


.line {
    width: 586px;
    height: 0;
    border: 1px #DEEFFC solid;
/*   margin-top: 21px;
    margin-bottom: 43px;*/
}

.contactRow {
    display: inline-flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
    align-self: flex-start;
    width: 586px;
}

.contactTitle {
    flex: 1 1 0;
    color:#979797;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
}

.contact {
    flex: 1 1 0;
    color:#2A2E37;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.changeButtonWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.modalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0;
}

.modalForm {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    align-self: stretch;
}

.modalBlock {
    display: flex;
    align-items: center;
    gap: 8px;
}

.modalDropdownForm {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    gap: 8px;
    align-self: stretch;
}

.modalDropdownSubtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    text-align: left;
    margin: 0;
    text-transform: uppercase;
}

.modalDropdown {
    display: flex;
    align-items: center;
    width: 100%;
}

.modalButtonsWrapper {
    display: flex;
    gap: 24px;
    margin-top: 60px;
    justify-content: center;
}

.modalButtonBlock {
    width: 180px;
}

@media only screen
and (max-device-width: 768px) {
    .modalButtonBlock {
        width: 100%;
    }
}


.error {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    position: absolute;
}


.popup_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    word-break: break-word;
    color: #2A2E37;
}

.popup_subtitle {
    display: block;
    text-align: center;
    width: 100%;
    font-weight: 400;
    line-height: 20px;
    color: #979797;
    margin-top: 20px;
    margin-bottom: 60px;
}

.btn_block {
    width: 180px;
}

.popup_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cardNumber {
    font-family: 'Inter', sans-serif;
    color: #2A2E37;
    font-size: 14;
    font-weight: 600;
    line-height: 18px;
}