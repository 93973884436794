.wrapper {
    display: flex;
    align-items: center;
    border: 2px solid #EEEFEF;
    background: #FFF;
    padding: 46px 16px;
    box-sizing: border-box;
    justify-content: space-between;
}

.right {
    display: flex;
    align-items: center;
    gap: 32px;
}

.login_wrapper {
    position: relative;
}

.login_header{
    position: relative;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
}

@media only screen
and (max-device-width: 768px) {

    .logo_login {
        position: absolute;
        width: 34px;
        height: 40px;
        top: 32px;
        left: 16px;
        z-index: 1;
    }

    .company_name {
        position: absolute;
        top: 45px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
        width: 127px;
        height: 13px;
    }


    .user_round {
        width: 60px;
        height: 60px;
        background: #EEEFEF;
        border: 1.5px solid #EEEFEF;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #979797;
        text-transform: uppercase;
    }

    .round {
        width: 50px;
        height: 50px;
        background: #FFFFFF;
        border: 1.5px solid #EEEFEF;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .mail {
        transition: .3s all ease;
        width: 24px;
        height: 24px;
        background-color: #2A2E37;
        display: block;
        mask-image: url("../../assets/images/personalAccount/header_message_icon.svg");
    }

    .round:hover .mail {
        transition: .3s all ease;
        background-color: #979797;
    }

    .left {
        display: flex;
        gap: 30px;
        align-items: flex-end;
    }

    .wrapper {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        padding: 40px 16px;
        box-sizing: border-box;
        justify-content: space-between;
        overflow: hidden;
    }

}
