.wrapper p {
    margin: 0;
    padding: 0;
}

.filters_block {
    display: flex;
    width: 864px;
    padding: 24px 32px;
    align-items: flex-end;
    border-radius: 12px;
    background: #FFF;
    gap: 24px;
}

.account_dropdown_block {
    width: 316px;
}

.form {
    margin-bottom: 37px;
}

.labelText {
    margin: 0 0 9px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
}

.button_wrapper {
    width: 120px;
}

.statement_wrapper {
    margin-top: 37px;
    background: #FFF;
    border-radius: 12px;
    width: 100%;
}

.table_top {
    padding: 24px 32px 48px;
}

.period {
    color:  #2A2E37;
    font-size: 24px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 32px;
}

.generate_link {
    color:  var(--primary-color);
    text-align: center;
    font-size: 14px;
    font-family: Inter, sans-serif;
    font-weight: 600;
    line-height: 18px;
    cursor: pointer;
}

.disable {
    pointer-events: none;
    cursor: default;
}

.disable div {
    color: #979797;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableWrapper p {
    margin: 0;
    padding: 0;
}

.tableWrapper {
    padding: 24px 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.balance {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    gap: 20px;
}

.statementTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px;
    line-height: 16px;
    color: #77797E !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: uppercase;
    border: none !important;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}

.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
    cursor: pointer !important;
}

.statementTable tbody > tr > td:first-child,
.statementTable tbody > tr > td:last-child,
.statementTable thead > tr > th:last-child,
.statementTable thead > tr > th:first-child{
    padding-left: 32px !important;
    padding-right: 32px !important ;
}

.statementTable tbody > tr > td:first-child {
    padding-bottom: 6px !important;
}


.open {
    transition: all .3s ease-in-out;
    transform: rotate(180deg);
}


.close {
    transition: all .3s ease-in-out;
    transform: rotate(0);
}

.table_row_dark {
    background-color: #EEEFEF !important;
}

.timeCellP {
    color: #979797;
}

.out {
    color: #FF6471;
}

.in {
    color: #74C69D;
}


.amountColumn > div:first-child {
    justify-content: flex-end !important;
    width: 100% !important;
}

.summaryWrapper {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding: 0 32px;
}

.summaryLine {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #2A2E37;
    padding: 22px 0;
    border-top: 1px solid #F6F9FF;
}

.summaryValue{
    min-width: 132px;
    font-weight: 400;
    text-align: right;
}

.sum {
    font-weight: 600;
}

.rejected {
    color: #FF6471;
    text-transform: capitalize;
}

.pending {
    text-transform: capitalize;
    color: #2A2E37;
}

.confirmed {
    text-transform: capitalize;
    color: #74C69D;
}

.statement_wrapper_mobile {
    display: none;
}

@media only screen
and (max-device-width: 768px) {
    .filters_block {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 32px 16px;
        gap: 24px;
    }

    .account_dropdown_block {
        width: 100%;
    }

    .button_wrapper {
        width: 100%;
    }

    .date_picker_block {
        width: 100%;
    }

    .statement_wrapper {
        display: none;
    }

    .form {
        margin-bottom: 72px;
    }

    .statement_wrapper_mobile {
        display: block;
    }

    .period {
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 32px;
    }

    .set_type_block {
        margin-bottom: 56px;
    }

    .generate_link {
        text-align: left;
    }

    .statement_mobile_body {
        margin-top: 72px;
    }

    .date_block_wrapper {
        display: flex;
        flex-direction: column;
        gap: 40px;
        margin-bottom: 72px;
        width: 100%;
    }

    .transaction_date {
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
    }

    .transactions_by_day_wrapper {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    .transaction {
        border-radius: 8px;
        background: #FFF;
        padding: 8px;
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .transaction_time {
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .modal_row {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        gap: 4px;
    }

    .transaction_info_row {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .transaction_type {
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .status {
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .amount {
        display: flex;
        align-items: center;
        color: #2A2E37;
        text-align: right;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    .transfer_details_block {
        margin-top: 32px;
    }

    .row_name {
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }

    .row_value {
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }

    .amount_block {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 32px;
    }

    .disable {
        color: #DBDBDB;
    }

    .international_details_block {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 24px;
    }

    .international_row_name {
        margin-bottom: 2px;
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .international_row_value {
        color: #2A2E37;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }
}

