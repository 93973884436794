.account_card {
    background: #fff;
    border-radius: 12px;
    padding: 32px 16px;
    margin-bottom: 16px;
}

.account_card_pending {
    background: #fff;
    border-radius: 12px;
    padding: 32px 16px;
    margin-bottom: 16px;
}

.label {
    color:  #979797;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.value {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.value_pending {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.flex_popover {
    display: flex;
    align-items: center;
    gap: 8px;
}

.row {
    margin-bottom: 32px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.label_total {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.block {
    width: 45%;
}

.pending {
    color: #FFBB54;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
