.my_btn {
    padding: 16px 0;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    border: none;
    transition: .3s all ease;
    cursor: pointer;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my_btn:hover {
    background: var(--secondary-color);
    transition: .3s all ease;
}

.img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.medium {
    height: 44px;
}

.large {
    height: 52px;
}

.small {
    height: fit-content;
}


.disabled,
.disabled:hover {
    background-color: #979797;
    transition: background-color .3ms ease-in;
}

.confirm {
    background-color: #FFBB54;
}

.confirm:hover  {
    background-color:#FEC979;
}

.error{
    background-color: #FF6471;
}

.error:hover  {
    background-color:#FF8A94;
}



@media only screen
and (max-device-width: 768px) {
    .my_btn {
        width: 100% !important;
    }
}
