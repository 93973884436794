.title {
    margin-bottom: 32px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
}

.row {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 16px;
}

.row_name {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
    width: 180px;
}

.row_value {
    max-width: max-content;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.buttons_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.button_wrapper {
    width: 180px;
}


.modal_content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-size: 24px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-size: 16px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_block {
    width: 180px;
    margin: 0 auto;
}

@media only screen
and (max-device-width: 768px) {
    .row_name {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #2A2E37;
        width: 50%;
    }

    .row span {
        max-width: initial;
        text-align: left;
        display: block;
        width: 50%;
        word-break: break-word;
    }

    .buttons_block {
        flex-direction: column;
        gap: 16px;
    }

    .button_wrapper {
        width: 100%;
    }

}
