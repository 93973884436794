.accountOverviewWrapper {
    background-color: #FFFFFF;
    padding: 24px 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    font-family: 'Inter', sans-serif;
}

.topMenu {
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
}

.statement {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
}

.statement:hover {
    cursor: pointer;
    color: var(--primary-color);
    transition: .3s all ease-in-out;
}


.topMenuTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
}

.topMenuButtons {
    display: flex;
    gap: 24px;
    align-items: center;
}

.arrow_img {
    width: 20px;
    height: 20px;
    transform: translateY(2px);
}

.accountTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: uppercase;
    border: none !important;
}

.accountTable thead > tr > th::before {
    content: none !important;
}

.accountTable tbody > tr > td {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
}

.accountTable tfoot  > tr > td{
    border: none !important;
}

.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.footerTotalCell {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 0 !important;
    border: none !important;
    height: 64px !important;
}

.footerTotalCell > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
}

.footerCell {
    padding: 0 !important;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}


.modalContent {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.modalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitleSuccess {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 24px;
    margin-top: 68px;
    justify-content: center;
}

.buttonsWrapperSuccess {
    display: flex;
    gap: 24px;
    margin-top: 60px;
    justify-content: center;
}

.checkboxes {
    display: flex;
    font-weight: 500;
    gap: 47px;
    justify-content: center;
    flex-wrap: wrap;
}


.pending {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #FFBB54;
    margin: 0;
    text-transform: lowercase;
}

.blocked {  font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    margin: 0;
    color: #FF6471;
    text-transform: lowercase;
}

.disabled {
    color: #979797;
    margin: 0;
}

.mobileRow {
    display: flex;
    gap: 24px;
    align-items: center;
}

@media only screen
and (max-device-width: 768px) {
    .accountOverviewWrapper {
        background-color: transparent;
        padding: 0 16px;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        font-family: 'Inter', sans-serif;
    }

    .topMenu {
        flex-direction: column-reverse;
        gap: 56px;
        margin-bottom: 40px;
        width: 100%;
    }

    .topMenuButtons {
        flex-direction: column-reverse;
    }

    .mobileRow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex-direction: row-reverse;
    }
}
