.accountTopUpWrapper {
    max-width: 720px;
    display: flex;
    flex-direction: column;
    gap: 36px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.detailsWrapper{
    background-color: #FFFFFF;
    padding: 24px 32px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.detailsTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
  margin: 0;
}

.labelText {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    display: flex;
    gap: 8px;
    margin-bottom: 9px;
}

.labelText img {
    cursor: pointer;
}

.line {
    display: flex;
    align-items: center;
    gap:24px;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}
.field {
    margin: 0;
    width: 225px;
}

.info {
    margin: 0;
    font-weight: 600;
    width: 400px;
}

.collapse_text {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.32px;
    margin-top: 18px;
}

.content {
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
}

.panelStyle {
    border-radius: 12px!important;
    border: 1.5px solid #EEEFEF!important;
    background: #FFF;
    padding: 14px 18px;
}

.panelStyle div {
    padding: 0!important;
    height: fit-content!important;
}

.panelStyle div span {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    align-self: center;
}

.topUpSubtitle {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 16px;
}

.tabstitle_block {
    border-radius: 8px;
    padding: 6px;
    border: 1.5px solid #EEEFEF;
    display: flex;
    list-style-type: none;
    margin-bottom: 36px;
    width: 100%;
}

.tabtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #2A2E37;
}

.tabtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
    width: 100%;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #2A2E37;
}

.active_tab {
    background: #E3F7FE;
    border-radius: 4px;
    color: var(--primary-color);
    outline: none;
}

.payment_method {
    color: #2A2E37;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    margin-right: 8px;
}

.payment_methods_block {
    width: 400px;
}

.icon_plus {
    width: 24px;
    height: 24px;
}

.icon_minus {
    width: 24px;
    height: 24px;
}

