.form {
    max-width: 284px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-bottom: 36px;
}


.periodRowContainer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; 
    gap: 16px;
}

.periodRow {
    padding: 25px 32px;
    background-color: #FFFFFF;
    border-radius: 12px;
    flex: 1;
}

.dropdown_wrapper {
    width: 280px;
}

.period, .account  {
    text-transform: initial;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}


.labelText {
    margin: 0 0 9px;
}


.buttonWrapper{
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 8px;
}

.tableWrapper p {
    margin: 0;
    padding: 0;
}

.tableWrapper {
    padding: 24px 0;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.headerWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
}

.infoWrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.balance {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    gap: 20px;
}

.statementTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #77797E !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: uppercase;
    border: none !important;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}

.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
    cursor: pointer !important;
}

.statementTable tbody > tr > td:first-child,
.statementTable tbody > tr > td:last-child,
.statementTable thead > tr > th:last-child,
.statementTable thead > tr > th:first-child{
    padding-left: 32px !important;
    padding-right: 32px !important ;
}

.statementTable tbody > tr > td:first-child
{
    padding-bottom: 6px !important;
}

.open {
    transition: all .3s ease-in-out;
    transform: rotate(180deg);
}

.close {
    transition: all .3s ease-in-out;
    transform: rotate(0);
}

.table_row_dark {
    background-color: #EEEFEF !important;
}

.timeCellP {
    color: #979797;
}

.out {
    color: #FF6471;
}

.in {
    color: #74C69D;
}

.transaction_type {
    text-transform: capitalize;
}

.amountColumn > div:first-child {
    justify-content: flex-end !important;
    width: 100% !important;
}

.summaryWrapper {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding: 0 32px;
}

.summaryLine {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    color: #2A2E37;
    padding: 22px 0;
    border-top: 1px solid #F6F9FF;;
}

.summaryValue{
    min-width: 132px;
    font-weight: 400;
    text-align: right;
}

.sum {
    font-weight: 600;
}

@media only screen
and (max-device-width: 768px) {
    .tableWrapper p {
        margin: initial;
        padding: initial;
    }

    .transactionsListMobile {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }

    .transfer {
        padding: 32px 16px;
        background: #fff;
    }

    .tableWrapper {
        flex-direction: column-reverse;
        background-color: transparent;
    }

    .headerWrapper {
        margin-top: 24px;
        border-radius: 12px;
        border: 1.5px solid #EEEFEF;
        padding: 32px 16px;
    }

    .mobileTransferRow {
        display: flex;
        align-items: flex-start;
        gap: 32px;
        margin-bottom: 32px;
    }

    .blockTitle {
        margin-bottom: 8px;
    }

    .blockTitle span {
        text-transform: uppercase;

    }
}
