.logo_block {
    margin-bottom: 64px;
    display: flex;
    justify-content: space-between;
}

.links_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 20px;
    text-align: left;
}


.nav_item {
    display: flex;
    width: 100%;
    height: 24px;
    align-items: center;
    cursor: pointer;
    margin-bottom: 32px;
}

.services_block {
    margin-top: 56px;
}

.logout {
    position: absolute;
    bottom: 12px;
    left: 48px;
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.logout:hover .img_logout {
    transition: .3s all ease;
    background: #292D32;
}

.text {
    margin-left: 16px;
}

.logout:hover .text {
    transition: .3s all ease;
    color: #2A2E37;
}

@media only screen
and (max-device-width: 768px) {
    .links_wrapper {
        padding-left: 78px;
    }

    .logout {
        left: 104px;
    }

    .links_title {
        color: #979797;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
    }
}
