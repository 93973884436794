body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.backgroundBounce + label:before,
.backgroundBounce + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    background-color: #c5c5c5;
    width: 60px;
    height: 60px;
    max-width: 100px;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.25s ease-in-out;
}

.backgroundBounce + label:before {
    animation: background-bounce 1.5s ease-out infinite;
}

.backgroundBounce + label:after {
    animation: background-bounce 1.5s ease-out 0.4s infinite;
}

.quick-link-mil {
    background-image: url('https://www.millionmakers.com/wp-content/uploads/2017/04/contact-us-quick-chat.png.webp');
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    bottom: 10%;
    right: 0.5%;
    height: 60px;
    width: 60px;
    z-index: 9;
}

@keyframes background-bounce {
    0% {
        opacity: 0.8;
        transform: scale(1);
    }
    70% {
        opacity: 0;
        transform: scale(1.5);
    }
    to {
        opacity: 0;
    }
}