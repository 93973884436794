.wrapper {
    max-width: 720px;
}

.common_desc {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.autentication_list_block {
    background: #FFFFFF;
    border-radius: 12px;
    width: 720px;
    box-sizing: border-box;
    padding: 24px 32px;
    margin-top: 36px;
}

.autentication_item {
    margin-bottom: 48px;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.autentication_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
}

.autentication_item_description {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
}

.title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.settings_desc {
    margin-bottom: 32px;
}

.desc_item {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #2A2E37;
    margin-bottom: 12px;
}

.qr_image {
    border: 1.5px solid #EEEFEF;
    border-radius: 12px;
    width: 160px;
    height: 160px;
}

.qr_block {
    display: flex;
    align-items: center;
    height: fit-content;
    margin-bottom: 48px;
    gap: 24px;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
}

.input_wrapper {
    margin-top: 8px;
    width: 288px;
    position: relative;
}

.error {
    position: absolute;
    bottom: -20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
}

.my_root_switch {
    background: transparent;
    border: 1.5px solid #EEEFEF;
    height: auto;
    display: flex;
}

.my_root_switch div {
    top: 4px!important;
}

.input_block {
    margin-bottom: 48px;
}

.buttons_block {
    display: flex;
    justify-content: space-between;
}

.button_wrapper {
    width: 180px;
}

@media only screen
and (max-device-width: 768px) {
    .autentication_list_block {
        width: 100%;
        padding: 32px 16px;
    }

    .my_root_switch {
        background: transparent;
        border: 1.5px solid #EEEFEF;
        height: 26px;
        display: flex;
        width: 45px;
    }

    .autentication_title {
        text-align: left;
    }
}
