.input_block {
    width: 317px;
    position: relative;
}

.textarea_block {
    width: 100%;
    position: relative;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.input_label img {
    cursor: pointer;
}

.color_popover {
    color: var(--secondary-color);
}

.row {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 24px;
}

.input_block div.flex_label {
    gap: 24px;
}

.block {
    margin-top: 48px;
}

.title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    margin-bottom: 32px;
}

.languages_dropdown {
    padding-top: 24px;
    width: 92px;
}

.short {
    width: 208px;
}

.input_block_fixed {
    width: 316px;
    position: relative;
}

.input_block div.flex_label {
    gap: 24px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.type_of_payment_active {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

.type_of_payment_inactive {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
}

.continue_block {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
}

.buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
}

.required {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #2A2E37;
}

.error_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}

.success_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.success_subtitle {
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 60px;
}

.success_button_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn_block {
    width: 180px;
}

.mobile_row {
    display: flex;
    gap: 16px;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}

@media only screen
and (max-device-width: 768px) {
    .row {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        margin-bottom: 24px;
    }

    .input_block_fixed {
        width: 100%;
    }

    .mobile_row {
        display: flex;
        gap: 8px;
        width: 100%;
        flex-direction: row;
    }

    .short {
        width: 100%;
    }

    .input_block {
        width: 100%;
    }

    .form_wrapper {
        width: 100%;
    }

    .buttonsWrapper {
        flex-direction: column;
        gap: 16px;
    }

    .btn_block {
        width: 100%;
    }
}
