.wrapper {
    display: flex;
    background: #F6F9FF;
}

.login_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 50%;
    background-image: url("../../../assets/images/common/login_background.svg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all ease;
}

.login_wrapper_content_right {
    width: 50%;
}

.logo_block {
    position: absolute;
    top: 40px;
    left: 60px;
}

.quest_block {
    position: absolute;
    top: 40px;
    right: 60px;
}

.sign_in_wrapper {
    height: 100vh;
    padding-left: 112px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.sign_in_content {
    width: 315px;
}

.title {
    font-family: 'Krona One', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 35px;
    color: #2A2E37;
    margin-bottom: 60px;
}

.subtitle {
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 40px;

}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
}

.input_block {
    margin-bottom: 20px;
    position: relative;
}

.forgot {
    text-align: right;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
    cursor: pointer;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}

.btn_block {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.my_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 156px;
    height: 48px;
    box-sizing: border-box;
    background: #E2B778;
    border: none;
    cursor: pointer;
    font-family: 'Century Gothic', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    transition: .3s all ease;
}

.my_btn:hover {
    background: #D6AD72;
    transition: .3s all ease;
}

.dont_have_account {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #979797;
}

.link {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--secondary-color);
    margin-left: 8px;
    text-decoration: none;
}

.linkTerms {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: var(--primary-color);
    text-decoration: none;
}

.error_block {
    height: 52px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;
    background: #FFE8EA;
    border-radius: 8px;
    color: #FF6471;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
}

.password_rules {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #979797;
    margin-top: 6px;
    margin-bottom: 20px;
}

.input_block div.flex_label {
    gap: 24px;
}

.flex_label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.type_of_account {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
}

@media only screen
and (max-device-width: 768px) {

    .login_wrapper_content_left {
        position: relative;
        height: 100vh;
        width: 100%;
        background-image: url("../../../assets/images/common/log_in_background.jpg");
        background-size: 140%;
        display: flex;
        justify-content: flex-start;
        transition: .3s all ease;
        flex-direction: column-reverse;
    }

    .sign_in_wrapper {
        width: 100%;
        height: auto;
        background: #F6F9FF;
        padding: 40px 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
    }

    .sign_in_content {
        width: 100%;
    }

    .my_input {
        font-family: 'Century Gothic', serif;
        width: 100%;
        height: 40px;
        background: #FFFFFF;
        padding: 14px 12px;
        box-sizing: border-box;
        outline: none;
        border: none;
        margin-top: 20px;
    }

    .subtitle {
        margin-bottom: 40px;
    }

    .logo_block {
        position: absolute;
        top: 20px;
        left: 16px;
    }
}
