.messages {
    width: 100%;
    height: 826px;
    margin: 192px 0;
}

.consultation {
    position: absolute;
    top: -22px;
    bottom: 0;
    right: 0;
    left: -1px;
    width: 332px;
    height: 81px;
    z-index: -1;
}

.contact_subtitle {
    color: #FFF;
    font-family: Mulish, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
}

.input_wrapper {
    width: 416px;
    margin-bottom: 16px;
    position: relative;
}

.input_label {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.contacts_button_wrapper {
    margin-top: 48px;
    display: flex;
    justify-content: flex-end;
    width: 416px;
}

.button_block {
    width: 180px;
}

.black_bg {
    background: #2A2E37;
}

.block {
    width: 100%;
    border-radius: 32px;
    padding: 88px 48px;
    min-height: 610px;
    box-sizing: border-box;
}

.gap20 {
    gap: 20px;
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.color_title_white {
    color: #FFF!important;
}

.decorated {
    color: #2A2E37;
    font-family: Mulish, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    position: relative;
    z-index: 1;
    display: inline-block;
    height: 24px;
}

.block_title {
    font-family: Mulish, sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 48px;
}

.contact_description {
    margin-top: 56px;
    color: #FFF;
    font-family: Mulish, sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    z-index: 1;
}

.gray_bg {
    background: #EEEFEF;
}

.error_text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -15px;
    position: absolute;
}

.popup_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 20px;
}

.popup_subtitle {
    color: #979797;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 60px;
}

.popup_button_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media only screen
and (max-device-width: 1240px) {
    .contacts_button_wrapper {
        width: 100%;
    }

    .input_wrapper {
        width: 100%;
    }
}

@media only screen
and (max-device-width: 768px) {
    .flex {
        flex-direction: column;
    }

    .input_wrapper {
        width: 100%;
    }

    .contacts_button_wrapper {
        width: 100%;
    }

    .block {
        padding: 66px 24px;
        min-height: initial;
    }
}

@media only screen
and (max-device-width: 504px)
and (min-device-width: 400px) {
    .consultation {
        position: absolute;
        top: 50px;
        bottom: 0;
        right: 0;
        left: -134px;
        width: 332px;
        height: 81px;
        z-index: -1;
    }

    .decorated {
        display: initial;
        height: 24px;
    }
}
