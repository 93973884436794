.desc_block {
    margin-bottom: 36px;
}

.desc_text {
    color: #2A2E37;
    font-size: 16px;
    font-family: Inter, sans-serif;
    line-height: 20px;
}

.table_wrapper {
    border-radius: 12px;
    width: 100%;
    background-color: #fff;
    padding: 24px 32px;
}

.form_block {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    margin-bottom: 48px;
}

.field_wrapper {
    display: flex;
    align-items: flex-end;
    gap: 16px;
}

.input_block {
    width: 208px;
    position: relative;
}

.input_label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: #979797;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.sell_currency_block {
    width: 92px;
    height: 52px;
}

.converted_amount_block {
    color: #2A2E37;
    font-size: 16px;
    font-family: Inter, sans-serif;
    line-height: 20px;
    margin-bottom: 48px;
}

.statementTable thead > tr > th {
    font-family: 'Inter', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px;
    line-height: 16px;
    color: #979797 !important;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 24px !important;
    text-transform: uppercase;
    border: none !important;
}

.statementTable thead > tr > td {
    background: transparent !important;
    border: none !important;
}

.statementTable thead > tr {
    padding: 0 32px !important;
    margin: 32px 0 !important;
    width: 100% !important;
}

.statementTable thead > tr > th > div > span {
    flex: initial !important;
}

.statementTable .ant-table-column-sorter {
    display: none !important;
}

.statementTable thead > tr > th > div {
    justify-content: flex-start !important;
    gap: 6px !important;
}


.numberWrapper {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
}

.statementTable thead > tr > th::before {
    content: none !important;
}

.statementTable tbody > tr > td {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    text-transform: uppercase;
    background-color: transparent !important;
    margin: 0 !important;
    padding: 22px 0 24px !important;
    height: fit-content !important;
    border-bottom: 1.5px solid #F6F9FF !important;
    cursor: pointer !important;
}

.tableCell {
    color: #2A2E37;
    text-align: right;
    font-size: 14px;
    font-family: Inter, sans-serif;
    line-height: 18px;
}

.iberbancoCell {
    color: #74C69D!important;
    text-align: right;
    font-size: 14px;
    font-family: Inter, sans-serif;
    line-height: 18px;
}

.official_rate {
    color: #2A2E37;
    font-size: 16px;
    font-family: Inter, sans-serif;
    line-height: 20px;
    margin-top: 36px;
}

.link {
    color: var(--primary-color);
    font-size: 16px;
    font-family: Inter, sans-serif;
    line-height: 20px;
    border-bottom: 1px solid var(--primary-color);
}

.mobile_official_rate {
    display: none;
}

.button_wrapper {
    width: 120px;
    margin-bottom: 20px;
}

.error_message {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FF6471;
    bottom: -20px;
    position: absolute;
}

@media only screen
and (max-device-width: 768px) {
    .table_wrapper {
        border-radius: 12px;
        width: 100%;
        background-color: #fff;
        padding: 32px 16px;
    }

    .field_wrapper {
        width: 100%;
    }

    .input_block {
        width: 100%;
    }

    .form_block {
        flex-direction: column;
        gap: 24px;
        margin-bottom: 48px;
    }

    .button_wrapper {
        width: 100%;
    }

    .official_rate {
        display: none;
    }

    .mobile_official_rate {
        display: block;
        color: #2A2E37;
        width: 249px;
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        margin-top: 48px;
        margin-bottom: 16px;
    }
}
