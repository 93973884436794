.cardsWrapper {
    display: flex;
    gap: 10px;
    padding-top: 24px;
    overflow-x: scroll;
    scroll-behavior: smooth;
    position: relative;
}

.cardsWrapperSingle {
    display: flex;
    justify-content: center;
    padding-top: 24px;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.cardsTitle {
    color: #979797;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
}

.buttonWrapper {
    padding-top: 50px;
    float: right;
    width: 142px;
}

.cardsWrapperSingle::-webkit-scrollbar {
    display: none;
}

.cardsWrapper::-webkit-scrollbar {
    display: none;
}

.cardsWrapper::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.navigationDots {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    display: flex;
    gap: 8px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #d9d9d9;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dot.active {
    background-color: #2046a1;
}

.card {
    flex: 0 0 auto;
    width: 350px;
    height: 200px;
    perspective: 1000px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.singleCard {
    flex: 0 0 auto;
    width: 350px;
    height: 200px;
    perspective: 1000px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.cardFront {
    background: url("/src/assets/images/personalAccount/card.svg");
    color: white;
    border-radius: 12px;
    width: 350px;
    height: 200px;
    overflow: hidden;
    position: relative;
}

.chip {
    width: 50px;
    height: 30px;
    left: 290px;
    top: 155px;
    position: absolute;
    background: url("/src/assets/images/personalAccount/chip.svg") center
        no-repeat;
    align-items: center;
}

.masterCard {
    width: 50px;
    height: 50px;
    left: 20px;
    top: 21px;
    position: absolute;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-flex;
    background: url("/src/assets/images/personalAccount/mastercard.svg") center
        no-repeat;
}

.cardNumber {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    left: 20px;
    top: 109px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    color: white;
    text-transform: capitalize;
}

.expireDate {
    left: 20px;
    top: 155px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    color: white;
    text-transform: capitalize;
}

.cardHolder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    left: 20px;
    top: 172px;
    text-align: center;
    position: absolute;
    text-transform: capitalize;
}

.noCardsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    min-height: 200px;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
}

.addButtonWrapper {
    width: 180px;
}

.cardIcon {
    width: 24px;
    height: 24px;
    display: block;
    padding: 0;
    background-color: #979797;
    mask-image: url("/src/assets/images/personalAccount/cards.svg");
    mask-repeat: no-repeat;
    margin-bottom: 10px;
}

.noCardsText {
    font-size: 16px;
    color: #979797;
    margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {
    .cardsWrapper,
    .cardsWrapperSingle {
        display: flex;
        gap: 10px;
        overflow-x: auto;
        box-sizing: border-box;
    }

    .singleCard {
        flex: 0 0 auto;
        width: 100%;
        max-width: 350px;
        height: auto;
    }
}