.steps_block {
    width: 145px;
}

.motion_div {
    height: 100%;
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
}

.motion_div_scrolled {
    background-color: #122932;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.wrapper {
    display: flex;
    background: #F6F9FF;
    position: relative;
    overflow: hidden;
}

.login_wrapper_content_left {
    position: relative;
    height: 100vh;
    width: 50%;
    background-image: url("../../assets/images/common/login_background.svg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all ease;
}

.login_wrapper_content_left > div > span {
    font-family: 'Krona One', sans-serif;
    color: white;
    font-size: 2.3vw;
    font-weight: 500;
    text-transform: uppercase;
    position: absolute; 
    left: 35%; transform: 
    translateX(-35%);
}

.login_wrapper_content_right {
    width: 50%;
}

.left_content {
    width: 85%;
    color: white;
}

.left_content_title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 51.6px;
    text-transform: uppercase;
    margin-bottom: 54.19px;
}

.left_content_description_block {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.description_block_icon {
    flex-shrink: 0;
    margin-right: 18px;
}

.description_block_icon > img {
    width: 40px;
    height: 40px;
}

.description_block_text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: inline-block;
    width: 70%;
}

.logo_block {
    position: absolute;
    top: 40px;
    left: 60px;
}

.quest_block {
    position: absolute;
    top: 40px;
    right: 60px;
}

.quest_block img {
    cursor: pointer;
}

.top_modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 32px;
    width: 100%;
    background-color: var(--primary-color);
    border-radius: 7px 7px 0 0;
}


.footer {
    position: absolute;
    height: 80px;
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 60px 31px;
    box-sizing: border-box;
}

.right_side {
    width: 832px;
    display: flex;
    gap: 28px;
    align-items: center;
    justify-content: flex-end;
}

.reg_number_link {
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #F6F9FF;
    transition: .5s all ease;
    margin-bottom: 8px;
}

.reg_number_link:hover {
    color: var(--secondary-color);
    transition: .5s all ease;
}

.copyright {
    color: #DFDBDB;
}

.footer_item {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
}

.modal_title {
    color: #2A2E37;
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 60px;
}

.subject_block {
    margin-bottom: 24px;
}

.subject_block span {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.message_block {
    margin-bottom: 60px;
    width: 100%;
}

.message_block span {
    color: #979797;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.buttons_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
}

.button_block {
    width: 180px;
}

.world_vector_bottom_left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    height: 50%;
    transform: translate(-20%, 35%);
    z-index: 1;
    opacity: 0.3;
}

@media only screen
and (max-device-width: 768px) {
    .wrapper {
        position: initial;
    }
    
    .world_vector_bottom_left {
        display: none;
    }

    .login_wrapper_content_left {
        display: none;
    }

    .login_wrapper_content_right {
        width: 100%;
        height: 100%;
    }

    .quest_block {
        position: absolute;
        top: 36px;
        right: 16px;
    }
}

