.widgetContainer {
  display: flex;
  gap: 39px;
}

.widgetWrapper {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 50%;
    max-width: 50%;
    padding: 24px 32px;
    position: relative;
    overflow: hidden;
}

.widgetWrapperFullWidth {
    margin-top: 50px;
    font-family: "Inter", sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2a2e37;
    background-color: #ffffff;
    border-radius: 12px;
    width: 100%;
    padding: 24px 32px;
    position: relative;
    overflow: hidden;
}

@media only screen and (max-device-width: 768px) {
    .widgetWrapper {
        width: 100%;
        max-width: 100%;
    }
    .widgetContainer {
        display: grid;
        gap: 50px;
    }
}
