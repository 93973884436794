
.pageWrapper {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #2A2E37;
    background-color: #FFFFFF;
    border-radius: 12px;
    width: 100%;
    gap: 34px;
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 24px 32px;
}

.card_wrapper {
    justify-content: center;
    align-items: flex-start;
    max-width: 1118px;
    gap: 48px;
    display: inline-flex;
}

.credit_card {
    width: 350px;
    height: 200px;
    perspective: 1000px;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.card_front {
    background: url('/src/assets/images/personalAccount/card.svg');
    color: white;
    border-radius: 12px;
    width: 350px;
    height: 200px;
    overflow: hidden;
    position: relative;
}

.card_info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;
    color: white;
}

.chip {
    width: 50px;
    height: 30px;
    left: 290px;
    top: 155px;
    position: absolute;
    background: url("/src/assets/images/personalAccount/chip.svg") center
        no-repeat;
    align-items: center;
}

.cardHolder {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 700;
    left: 20px;
    top: 172px;
    text-align: center;
    position: absolute;
    text-transform: capitalize;
}

.copyButton {
    border: none;
    background: none;
    padding-left: 5px;
    cursor: pointer;
    display: inline-flex;
    vertical-align: middle;
}

.masterCard {
    width: 50px;
    height: 50px;
    left: 20px;
    top: 21px;
    position: absolute;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-flex;
    background: url("/src/assets/images/personalAccount/mastercard.svg") center
        no-repeat;
}

.cardNumber {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    left: 20px;
    top: 109px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    color: white;
    text-transform: capitalize;
}

.expireDate {
    left: 20px;
    top: 155px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    color: white;
    text-transform: capitalize;
}

.cardDetailsWrapper {
    width: 600px;
    align-self: stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 35px;
    display: inline-flex;
}

.detailRow {
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    display: inline-flex;
}

.detailBlock {
    justify-content: flex-start;
    gap: 24px;
    display: inline-flex
}


.detailTitle {
    width: 300px;
    color: #2A2E37;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    word-wrap: break-word;
}

.detail {
    flex: 1 1 0;
    color: var(--primary-color);
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    word-wrap: break-word;
    margin-top: 10px;
}

.view {
    display: inline-flex;
    align-items: center;
    padding-left: 5px;
    vertical-align: middle;
}

.buttonWrapper {
    width: 100%;
    display: flex;
    justify-content: right;
    margin-top: 8px;
}

.hr {
    width: 100%;
    height: 100%;
    border: 1px #DEEFFC solid;
}

.blockWrapper {
    max-width: 732px;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 22px;
    gap: 12px;
}

.blockTitle {
    min-width: 220px;
    color: #2A2E37;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
}

.blockText {
    color: #979797;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding: 16px 0;
    max-width: 400px;
    word-wrap: break-word
}


.tabstitle_block {
    width: 557px;
    border-radius: 8px;
    padding: 6px;
    border: 1.5px solid #EEEFEF;
    display: flex;
    list-style-type: none;
    margin-bottom: 36px;
}

.tabtitle {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-decoration: none;
    padding: 14px 20px;
    cursor: pointer;
    transition: .3s all ease;
    width: 50%;
    text-align: center;
}

.tabtitle:hover {
    transition: .3s all ease;
    color: #2A2E37;
}

.active_tab {
    background: #FFFFFF;
    border-radius: 4px;
    color: var(--primary-color);
    outline: none;
}

.modalContent {
    display: flex;
    flex-direction: column;
    align-content: center;
}

.modalTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #2A2E37;
    text-align: center;
    margin: 0 0 20px;
}

.modalSubtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #979797;
    text-align: center;
    margin: 0;
}

.buttonsWrapper {
    display: flex;
    gap: 24px;
    margin-top: 60px;
    justify-content: center;
}

.buttonBlock {
    width: 180px;
}

@media only screen
and (max-device-width: 768px) {
    .buttonBlock {
        width: 100%;
    }
}

.redText {
    color:#FF6471;
}


